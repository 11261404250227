/* eslint-disable @next/next/no-img-element */
import CreatableSelect from "react-select/creatable";
import Dropzone from "react-dropzone";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import AsyncSelect from "react-select/async";
import {
  camelCaseToNormalCase,
  inputValueChange,
  removeItemOnce,
} from "@/context/utilities";

export const BasicInput = ({ k, state, setState }) => {
  return (
    <div className={`my-1 ${k.width}`}>
      {k?.label && (
        <label htmlFor={k.name} className="form-label">
          {k?.label} {(k?.required === "true" || k?.required === true) && "*"}
        </label>
      )}

      <div className={k?.icon ? "input-group" : ""}>
        {k?.icon && (
          <div className="input-group-text bg-white">
            <i className={`${k?.icon} p-2`}></i>
          </div>
        )}

        <input
          className={`${k?.size ? `form-control-${k?.size}` : "form-control"} ${
            k?.icon ? "form-control-icon" : ""
          } ${k?.rounded ? "rounded-pill" : ""} border ${k?.error}`}
          type={k?.element}
          id={k?.name}
          name={k?.name}
          readOnly={k?.readOnly}
          placeholder={k?.placeholder ? k?.placeholder : `${k?.label}`}
          required={k?.required || false}
          value={state?.[k?.name] || ""}
          onChange={(e) =>
            inputValueChange(e, k?.element, k?.name, state, setState)
          }
        />

        {k?.description && (
          <div
            id={`${k?.name}HelpBlock`}
            className={`form-text ${k?.descriptionProps}`}
          >
            {k?.description}
          </div>
        )}
      </div>
    </div>
  );
};

export const SelectInput = ({ k, state, setState }) => {
  return (
    <div key={k?.name} className={`${k.width} my-1`}>
      <label className="text-capitalize form-label">
        {k?.label} {k?.required && "*"}
      </label>

      <CreatableSelect
        classNamePrefix="select"
        className="react-select"
        isMulti={
          typeof k?.variants !== "string"
            ? k?.variants?.includes("multiple")
            : k?.variants?.split(",")?.includes("multiple")
        }
        isDisabled={k?.disabled}
        isLoading={
          typeof k?.variants !== "string"
            ? k?.variants?.includes("loading")
            : k?.variants?.split(",")?.includes("loading")
        }
        isClearable={
          typeof k?.variants !== "string"
            ? k?.variants?.includes("clearable")
            : k?.variants?.split(",")?.includes("clearable")
        }
        isRtl={
          typeof k?.variants !== "string"
            ? k?.variants?.includes("rtl")
            : k?.variants?.split(",")?.includes("rtl")
        }
        value={
          state && typeof state[k?.name] == "string"
            ? {
                label: state?.[k?.name],
                value: state?.[k?.name],
              }
            : state?.[k?.name]?.map((i) => {
                return {
                  label: i,
                  value: i,
                };
              })
        }
        isSearchable={
          typeof k?.variants !== "string"
            ? k?.variants?.includes("searchable")
            : k?.variants?.split(",")?.includes("searchable")
        }
        placeholder={
          k?.placeholder ? k?.placeholder : `Search for ${k?.label}s`
        }
        onChange={(e) => {
          setState({
            ...state,
            [k?.name]:
              typeof k?.variants !== "string"
                ? k?.variants?.includes("multiple")
                  ? e.map((i) => i?.value)
                  : e?.value
                : k?.variants?.split(",")?.includes("multiple")
                ? e.map((i) => i?.value)
                : e?.value,
          });
        }}
        options={(k?.items?.split(",") || [])?.map((i) => {
          return {
            label: i,
            value: i,
          };
        })}
      />
    </div>
  );
};
export const BasicSelect = ({ k, state, setState }) => {
  return (
    <div className={`my-1 ${k.width}`}>
      {k?.label && (
        <label htmlFor={k.name} className="form-label">
          {k?.label} {(k?.required === "true" || k?.required === true) && "*"}
        </label>
      )}

      <select
        disabled={k?.disabled}
        className={`${k?.size ? `form-select-${k?.size}` : "form-select "} ${
          k?.icon ? "form-select-icon" : ""
        } ${k?.rounded ? "rounded-pill" : ""} border ${k?.error}`}
        type={k?.element}
        id={k?.name}
        name={k?.name}
        readOnly={k?.readOnly}
        placeholder={k?.placeholder ? k?.placeholder : `${k?.label}`}
        required={k?.required || false}
        value={state?.[k?.name] || ""}
        onChange={(e) =>
          inputValueChange(e, k?.element, k?.name, state, setState)
        }
      >
        <option>Select {camelCaseToNormalCase(k?.name)}</option>
        {k?.options &&
          k?.options.map((i) => {
            return (
              <option
                value={k.value ? i[k.value] : i}
                key={`name_${camelCaseToNormalCase(k?.name)}_${
                  k.value ? i[k.value] : i
                }_${k.display ? i[k.display] : i}`}
                className="m-1"
              >
                {k.display ? i[k.display] : i}
              </option>
            );
          })}
      </select>
      {k?.description && (
        <div
          id={`${k?.name}HelpBlock`}
          className={`form-text ${k?.descriptionProps}`}
        >
          {k?.description}
        </div>
      )}
    </div>
  );
};

export const AsyncSelectInput = ({ k, state, setState }) => {
  return (
    <div key={k?.name} className="my-2">
      <label className="text-capitalize">
        {k?.label} {k?.required && "*"}
      </label>

      <AsyncSelect
        className={`${k?.rounded && "rounded-pill"}`}
        isMulti={
          typeof k?.variants !== "string"
            ? k?.variants?.includes("multiple")
            : k?.variants?.split(",")?.includes("multiple")
        }
        isDisabled={k?.disabled}
        isLoading={
          typeof k?.variants !== "string"
            ? k?.variants?.includes("loading")
            : k?.variants?.split(",")?.includes("loading")
        }
        isClearable={
          typeof k?.variants !== "string"
            ? k?.variants?.includes("clearable")
            : k?.variants?.split(",")?.includes("clearable")
        }
        isRtl={
          typeof k?.variants !== "string"
            ? k?.variants?.includes("rtl")
            : k?.variants?.split(",")?.includes("rtl")
        }
        isSearchable={
          typeof k?.variants !== "string"
            ? k?.variants?.includes("searchable")
            : k?.variants?.split(",")?.includes("searchable")
        }
        placeholder={
          k?.placeholder ? k?.placeholder : `Search for ${k?.label}s`
        }
        value={
          state && typeof state[k?.name] == "string"
            ? {
                label: state?.[k?.name],
                value: state?.[k?.name],
              }
            : state?.[k?.name]?.map((i) => {
                return {
                  label: i,
                  value: i,
                };
              })
        }
        onChange={(e) => {
          setState({
            ...state,
            [k?.name]: e?.value[k.items.split(",")[1]],
          });
        }}
        loadOptions={async (inputValue) => {
          try {
            const response = await axios.get(
              `/${k.options}&search=${inputValue}`
            );
            const data = response.data;
            const options = data.map((item) => ({
              value: item,
              label: item[k.items.split(",")[0]],
            }));
            return options;
          } catch (error) {
            console.error("Error loading options:", error);
            return [];
          }
        }}
      />
    </div>
  );
};

export const TextareaInput = ({ k, state, setState }) => {
  return (
    <div className={`my-1 ${k.width}`}>
      {k?.label && (
        <label htmlFor={k.name} className="form-label">
          {k?.label} {(k?.required === "true" || k?.required === true) && "*"}
        </label>
      )}

      <textarea
        className={`rbt-form-group border ${k?.error}`}
        id={k?.name}
        required={k?.required || false}
        rows={k?.rows || "3"}
        placeholder={k?.placeholder ? k?.placeholder : `${k?.label}`}
        value={state?.[k?.name]}
        onChange={(e) =>
          inputValueChange(e, "textarea", k?.name, state, setState)
        }
      />
    </div>
  );
};

export const CheckBoxInput = ({ k, state, setState }) => {
  return (
    <div className="checkbox-grp" key={k?.name}>
      {k?.label && (
        <label htmlFor={k?.name} className="text-capitalize">
          {k?.label} {k?.required && "*"}
        </label>
      )}
      {k?.description && (
        <div id={`${k?.name}HelpBlock`} className="form-text">
          {k?.description}
        </div>
      )}

      <div className="d-flex flex-wrap">
        {k?.items?.length !== 0
          ? k?.items?.split(",")?.map((item) => {
              const variable = item?.split("-")?.[0];
              const description = item?.split("-")?.[1];
              return (
                <div key={variable}>
                  <div className="d-flex flex-wrap justify-content-start align-items-center">
                    <input
                      onChange={(e) => {
                        inputValueChange(
                          e,
                          k.element,
                          k?.name,
                          state,
                          setState
                        );
                      }}
                      id={variable}
                      value={variable}
                      name={variable}
                      type={k.element}
                      checked={state?.[k?.name]?.includes(variable)}
                      className={`border ${k?.error}`}
                      // required={k?.required || false}
                    />
                    <label
                      className={`text-capitalize ${k?.error} mx-3 my-1 form-check-label`}
                      htmlFor={variable}
                    >
                      {variable}
                    </label>
                  </div>
                  {description && (
                    <div id={`${variable}_description`} className="form-text">
                      {description}
                    </div>
                  )}
                </div>
              );
            })
          : "No Items Found"}
      </div>
    </div>
  );
};

export const MultipleInput = ({ k, state, setState }) => {
  const [open, setOpen] = useState(false);
  const [num, setNum] = useState(0);
  const [values, setValues] = useState({});
  const [newNote, setNewNote] = useState("");
  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newItems = [...state[k.name]];
    newItems[index] = {
      ...newItems[index],
      [name?.toString()?.replace(/ /g, "_")?.toLowerCase()]: value,
    };
    setState({
      ...state,
      [k.name]: newItems,
    });
  };
  return (
    <div className="mt-2" key={k?.name}>
      <div
        key={k.name}
        className={`${k.width} flex flex-row items-center justify-between`}
      >
        <label className="text-capitalize">
          {k?.label} {k?.required && "*"}
        </label>
        <div className={`w-fit ${k?.label ? "mb-3" : ""}`}>
          <button
            onClick={() => {
              const newItem = {};
              setState({
                ...state,
                [k.name]: [...(state[k.name] || []), newItem],
              });
              setNewNote("");
            }}
          >
            Add {k?.label}
          </button>
        </div>
      </div>
      <ul className={`list-inside ${k?.label ? "mb-4" : ""} space-y-2`}>
        {state?.[k.name] &&
          state?.[k?.name]?.map((i, index) => {
            return (
              <li key={i}>
                <div className="relative flex flex-col justify-start gap-4 dark:bg-gray-800 md:flex-row">
                  {k.items
                    .toString()
                    .split(",")
                    .map((l) => {
                      return (
                        <div
                          key={l}
                          className={`mr-2 w-full flex justify-start flex-col items-start ${
                            k?.label ? "mb-4" : ""
                          }`}
                        >
                          <label htmlFor={l} className="form-label fw-bold">
                            {camelCaseToNormalCase(l)}
                          </label>
                          <input
                            className={`${
                              k?.size
                                ? `form-control-${k?.size}`
                                : "form-control"
                            } ${k?.icon ? "form-control-icon" : ""} ${
                              k?.rounded ? "rounded-pill" : ""
                            } `}
                            type={k?.element}
                            id={l}
                            name={l}
                            htmlFor={l}
                            required={k?.required}
                            value={
                              state[k.name][index][
                                l.replace(/ /g, "_").toLowerCase()
                              ]
                            }
                            onChange={(event) => handleChange(index, event)}
                          />
                        </div>
                      );
                    })}

                  <button
                    color="danger"
                    onClick={() => {
                      const newArr = removeItemOnce(state[k.name], i);
                      setState({ ...state, [k.name]: newArr });
                    }}
                  >
                    Delete
                  </button>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export const RadioInput = ({ k, state, setState }) => {
  return (
    <div key={k?.name} className="my-2">
      {k?.label && (
        <label htmlFor={k.name} className={`form-label`}>
          {k?.label} {(k?.required === "true" || k?.required === true) && "*"}
        </label>
      )}

      {k?.description && (
        <div id={`${k?.description}_description`} className="form-text">
          {k?.description}
        </div>
      )}
      <div className="d-flex flex-wrap">
        {k?.items?.length !== 0
          ? k?.items?.split(",")?.map((item) => {
              const variable = item?.split("-")?.[0];
              const description = item?.split("-")?.[1];
              return (
                <div className="mx-2 my-1" key={variable}>
                  <div className="">
                    <input
                      onChange={(e) =>
                        inputValueChange(e, k.element, k.name, state, setState)
                      }
                      id={variable}
                      aria-describedby={variable}
                      type={k.element}
                      name={k.name}
                      value={variable}
                      // checked={state?.[k.name]?.includes(variable)}
                    />
                    <label className="m-2 text-capitalize" htmlFor={variable}>
                      {variable}
                    </label>
                  </div>
                  {description && (
                    <div id={`${variable}_description`} className="form-text">
                      {description}
                    </div>
                  )}
                </div>
              );
            })
          : "No Items Found"}
      </div>
      {k?.error && (
        <div id={`${k?.error}_description`} className="form-text text-danger">
          This field Is required
        </div>
      )}
    </div>
  );
};

export const ImageInput = ({ k, state, setState }) => {
  return (
    <div>
      <label className="text-capitalize">
        {k?.label} {k?.required && "*"}
      </label>

      <Dropzone
        maxFiles={parseInt(k.items)}
        onDrop={(acceptedFiles) => {
          setState({
            ...state,
            [k.name]: acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
                selectednames: ["Select"],
                cover: "",
              })
            ),
          });
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="avatar-upload">
            <div
              {...getRootProps()}
              className="flex justify-start my-4 items-center w-full"
            >
              <label htmlFor="dropzone-file">
                {state?.[k?.name] ? (
                  typeof state?.[k?.name] == "string" ? (
                    <div className="col-6">
                      <img
                        className="rounded-circle avatar-xl"
                        alt="200x200"
                        width="200"
                        src={state?.[k?.name]}
                      />
                    </div>
                  ) : (
                    state?.[k?.name]?.map((i) => (
                      <div key={i.preview} className="col-6">
                        <img
                          className="rounded-circle avatar-xl"
                          alt="200x200"
                          width="200"
                          src={i.preview}
                        />
                      </div>
                    ))
                  )
                ) : (
                  <div className="col-6">
                    <img
                      className="rounded-circle avatar-xl"
                      alt="200x200"
                      width="200"
                      // src={avatar4}
                    />
                  </div>
                )}
                <input
                  {...getInputProps()}
                  id="dropzone-file"
                  type="file"
                  // required={required ? required :k.required === "true" ? true : false}
                  name={k.name}
                  className="hidden"
                  accept=".png, .jpg, .jpeg"
                />
              </label>
            </div>
            {/* <i className="bx bx-save position-absolute top-10 start-10"></i> */}
          </div>
        )}
      </Dropzone>
      <button
        type="button"
        onClick={(e) => {
          var items = [];
          typeof state?.[k.name] == "object" &&
            state?.[k.name]?.map((i) => {
              const formData = new FormData();
              formData.append("file", i);
              axios
                .post(`/file/upload`, formData)
                .then((res) => {
                  if (k.name == "photoURL" || k.name == "thumbnail") {
                    setState({
                      ...state,
                      [k.name]: res.results[0].Location,
                    });
                  } else {
                    items.push(res.results[0].Location);
                  }
                  toast.success(`${k.name} Added`, { toastId: k.name });
                  // window.location.reload();
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          if (k.name == "photoURL" || k.name == "thumbnail") {
            setState({ ...state, [k.name]: items });
          }
        }}
      >
        Upload
      </button>
    </div>
  );
};
